<template lang="pug">
v-app
  v-navigation-drawer(
    :mobile-breakpoint="0",
    app,
    clipped,
    left,
    v-model="drawer"
  )
    v-list-item
      v-list-item-content
        v-list-item-title.text-h6 {{ greeting }}
        v-list-item-subtitle Let's learn us some Ruski 😎
    v-divider
    v-list.pt-0(
      dense
    )
      v-list-item.umami--click--open-vocab-trainer(
        @click="drawer = !drawer",
        to="/trainer"
      )
        v-list-item-action
          v-icon mdi-human-male-board
        v-list-item-content
          v-list-item-title Vocabulary Trainer
      v-divider
      v-list-item.umami--click--open-alphabet-trainer(
        @click="drawer = !drawer",
        to="/alphabet"
      )
        v-list-item-action
          v-icon mdi-alphabetical-variant
        v-list-item-content
          v-list-item-title Alphabet Trainer
      v-divider
      v-list-item.umami--click--open-about-page(
        @click="drawer = !drawer",
        to="/about"
      )
        v-list-item-action
          v-icon mdi-help-circle-outline
        v-list-item-content
          v-list-item-title About
      v-divider
      v-list-item.umami--click--open-impressum(
        @click="drawer = !drawer",
        to="/imprint"
      )
        v-list-item-action
          v-icon mdi-scale-balance
        v-list-item-content
          v-list-item-title Impressum
      v-divider
      v-list-item.umami--click--open-source-code(
        href="https://gitlab.com/detroitenglish/ruskablio",
        rel="noopener",
        target="_blank"
      )
        v-list-item-action
          v-icon mdi-source-repository
        v-list-item-content
          v-list-item-title View Source Code&nbsp;
            v-icon.pb-1.pl-1(
              small
            ) mdi-open-in-new
      v-divider
  v-app-bar(
    app,
    clipped-left,
    color="primary"
  )
    v-app-bar-nav-icon.umami--click--open-nav-menu(
      @click="drawer = !drawer",
      title="Open App Menu"
    )
    v-toolbar-title.pl-1
      v-flex.d-none.d-sm-flex.mr-3.ml-1
        img.logo(
          @click="drawer && (greeting = `СУКА бЛЯТЬ!`)",
          alt="Russiaball logo",
          height="40px",
          src="@/assets/logo.png",
          width="42px"
        )
      v-flex(
        :class="$vuetify.breakpoint.name === `xs` ? `mb-0` : `mt-1`"
      )
        span.headline.font-weight-medium Ruskablio
  v-main(
    app
  )
    v-slide-y-reverse-transition(
      mode="out-in"
    )
      router-view(
        :ready="ready",
        v-if="ready"
      )
      v-container(
        fill-height,
        fluid,
        v-else
      )
        v-layout(
          align-center,
          justify-center
        )
          v-flex(
            shrink
          )
            v-progress-circular(
              color="primary",
              indeterminate,
              size="72",
              width="8"
            )
  v-footer(
    app
  )
    span.caption
      | Built with ❤ in Hamburg by&nbsp;
      a.font-weight-medium.built-by(
        @click="openLink",
        href="https://www.detroit-english.de/?utm_source=ruskablio&utm_medium=referral",
        rel="noopener",
        style="text-decoration: none",
        target="_blank"
      ) Detroit English
</template>

<script>
import '@/styles/custom.css'
export default {
  data: () => ({
    drawer: false,
    ready: false,
    greeting: `Привет!`,
  }),
  async beforeMount() {
    await import(`@/assets/pouchdb.js`)

    let db = await this.$bucket.db

    if (!PROD) window.db = db

    let dbHash = localStorage.getItem(`dbHash`)

    const hashDiff = dbHash !== DB_HASH
    if (hashDiff || !dbHash) {
      if (hashDiff) {
        db = await this.$bucket.nuke()
      }
      let { default: docs } = await import(
        /* webpackChunkName: "vocab" */ `@/assets/ruskablio.js`
      )
      await db.bulkDocs({ docs }).catch(console.error.bind(console))

      localStorage.setItem(
        `dbHash`,
        await db
          .get(`hash`)
          .then(doc => doc.hash)
          .catch(err => (console.error(err), `0`))
      )
    }
    this.ready = true
  },
  methods: {
    openLink() {
      umami(`Open DTE website`)
    },
  },
}
// We just need these values in here for building custom icons font
// component icon: mdi-checkbox-blank-outline
// component icon: mdi-checkbox-marked
// component icon: mdi-chevron-left
// component icon: mdi-chevron-right
// component icon: mdi-arrow-up
// component icon: mdi-arrow-down
// component icon: mdi-menu
</script>
