import "/mnt/c/Users/DaveWillenberg/projects/ruskablio/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.array.iterator.js";
import "/mnt/c/Users/DaveWillenberg/projects/ruskablio/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.promise.js";
import "/mnt/c/Users/DaveWillenberg/projects/ruskablio/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.object.assign.js";
import "/mnt/c/Users/DaveWillenberg/projects/ruskablio/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.promise.finally.js";
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
if (!window.umami) window.umami = console.log;
Vue.config.productionTip = false;
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount(`#app`);