/* eslint-disable no-console */
import { register } from 'register-service-worker';

if (process.env.NODE_ENV === `production`) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(`💩: App being served from cache`);
    },

    registered() {// console.log(`💩: Service worker registered`)
    },

    cached() {
      console.log(`💩: Content cached for offline use`);
    },

    updatefound() {
      console.log(`💩: New content downloading`);
    },

    updated() {
      console.log(`💩: New content available`);
    },

    offline() {
      console.log(`💩: No internet connection; app in offline mode`);
    },

    error(error) {
      console.error(`💩: Error during service worker registration:`, error);
    }

  });
}