import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
Vue.use(Vuetify);
export default new Vuetify({
  theme: {
    dark: true,
    default: `dark`,
    themes: {
      dark: {
        primary: `#3F48CC`,
        secondary: `#ED1C24`
      }
    }
  },
  icons: {
    iconfont: `mdi`
  }
});